/**
 * Gainsight PX SDK docs can be found here https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
 */

// Extend the Window interface to include the Gainsight properties
declare global {
  interface Window {
    aptrinsic: any;
  }
}

export interface GainsightIdentifyPayload {
  user: {
    // User Fields
    id: string | number; // Required for logged in app users
    email: string;
    firstName: string;
  };
  account?: {
    // Account Fields
    id: string | number; // Required
    name?: string;
  };
}

export const addGainsightPXCode = (gainsightTagKey: string): void => {
  if (!gainsightTagKey) {
    return;
  }

  // Initialize the aptrinsic function on the global window object
  // This needs to be done before loading the script
  window.aptrinsic =
    window.aptrinsic ||
    function (...args: any[]) {
      (window.aptrinsic.q = window.aptrinsic.q || []).push(args);
    };

  // Set the required configuration
  window.aptrinsic.p = gainsightTagKey;
  window.aptrinsic.c = { iframeModeEnabled: true };

  // Create and append the script element to load the SDK
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${gainsightTagKey}`;
  scriptElement.setAttribute('data-gainsight-px', 'true');

  document.head.appendChild(scriptElement);
};

export const identifyGainsightUser = (
  gainsightTagKey: string,
  data: GainsightIdentifyPayload,
): void => {
  if (!gainsightTagKey) {
    return;
  }

  try {
    const { user, account } = data;
    window.aptrinsic('identify', user, account);
  } catch (e) {
    // aptrinsic is not defined
  }
};

export const gainsightPXCustomEvent = (eventName: string, data: any): void => {
  try {
    window.aptrinsic('track', eventName, data);
  } catch (e) {
    // aptrinsic is not defined
  }
};
