import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccredishareApiService } from './accredishare-api.service';
import {
  AccredishareGenerateHashtagResponse,
  AccredishareRequest,
  AccredishareTrackResponse,
  EventType,
  PlatformType,
  ReferralData,
  ShareData,
  VisitData,
} from './accredishare.model';

type HashTagType = 'acc.' | 'gs.';

@Injectable({
  providedIn: 'root',
})
export class AccredishareService {
  readonly ACCREDISHARE_STORAGE = 'acc_session_id';
  private _storageService = inject(AccredibleBrowserStorageService);
  private _document = inject(DOCUMENT);
  private _platform = inject(Platform);
  private _location = inject(Location);
  private _route = inject(ActivatedRoute);
  private _accredishareApi = inject(AccredishareApiService);
  private _recipientFeatureFlags = inject(AccredibleRecipientFeatureFlagsService);

  trackShare(credentialUuid: string, utmSource: string): Observable<AccredishareTrackResponse> {
    const request = this._createRequest(EventType.SHARE, {
      credential_uuid: credentialUuid,
      utm_source: utmSource,
    });
    return this._accredishareApi
      .track(request)
      .pipe(tap((response) => this._handleSessionId(response)));
  }

  trackVisit(data: VisitData): Observable<AccredishareTrackResponse> {
    const request = this._createRequest(EventType.VISIT, data);
    return this._accredishareApi
      .track(request)
      .pipe(tap((response) => this._handleSessionId(response)));
  }

  trackReferral(data: ReferralData): Observable<AccredishareTrackResponse> {
    const request = this._createRequest(EventType.REFERRAL, data);
    return this._accredishareApi
      .track(request)
      .pipe(tap((response) => this._handleSessionId(response)));
  }

  trackWallet(): Observable<
    [AccredishareTrackResponse, AccredishareTrackResponse] | { hashtag: string }
  > {
    return this._track();
  }

  trackCredential(
    credentialUuid: string,
  ): Observable<[AccredishareTrackResponse, AccredishareTrackResponse] | { hashtag: string }> {
    return this._track(credentialUuid);
  }

  /**
   * Track mechanism for wallets or credentials.
   * If there is a referral data, it will track the referral and visit.
   * If there is no referral data, it will generate a new visit.
   */
  private _track(
    credentialUuid?: string,
  ): Observable<[AccredishareTrackResponse, AccredishareTrackResponse] | { hashtag: string }> {
    const { fragment, queryParams } = this._getRouteInfo();
    const referralData = this._createReferralData(credentialUuid, fragment, queryParams);

    if (this._hasReferralData(referralData)) {
      return this._handleReferralAndVisit(referralData);
    }

    return this._handleNewReferral(credentialUuid);
  }

  private _handleReferralAndVisit(
    referralData: ReferralData,
  ): Observable<[AccredishareTrackResponse, AccredishareTrackResponse]> {
    return forkJoin([
      this.trackReferral(referralData).pipe(catchError(() => of(null))),
      this.trackVisit({ credential_uuid: referralData.credential_uuid }).pipe(
        catchError(() => of(null)),
      ),
    ]).pipe(
      catchError(
        this._handleError<[AccredishareTrackResponse | null, AccredishareTrackResponse | null]>(),
      ),
    );
  }

  private _handleNewReferral(credentialUuid: string): Observable<{ hashtag: string | null }> {
    return forkJoin({
      hashtag: this._accredishareApi
        .generateHashtag(credentialUuid)
        .pipe(catchError(this._handleError<AccredishareGenerateHashtagResponse>())),
      visit: this.trackVisit({ credential_uuid: credentialUuid }).pipe(
        catchError(this._handleError<AccredishareTrackResponse>()),
      ),
    }).pipe(
      map(({ hashtag }) => ({
        hashtag: hashtag ? hashtag.hashtag : null,
      })),
      tap(({ hashtag }) => {
        if (hashtag) {
          this._updateUrlWithHashtag(hashtag);
        }
      }),
    );
  }

  private _createRequest(
    eventType: EventType,
    data: ShareData | VisitData | ReferralData,
  ): AccredishareRequest {
    const sessionId = this._getSessionId();
    return {
      event_type: eventType,
      data: {
        ...data,
        ...(sessionId && { session_id: sessionId }),
        credential_url: this._document.location.href,
        platform: this._getPlatform(),
      },
    };
  }

  private _createReferralData(
    credentialUuid: string | null,
    fragment: string | null,
    queryParams: Params,
  ): ReferralData {
    const hashtag = this._getHashTag(fragment);
    const { utm_medium: utmMedium, utm_source: utmSource } = queryParams;

    return {
      credential_uuid: credentialUuid,
      ...(hashtag && { hashtag }),
      ...(utmMedium && { utm_medium: utmMedium }),
      ...(utmSource && { utm_source: utmSource }),
    };
  }

  private _updateUrlWithHashtag(hashtag: string): void {
    const { accredishare_tracking } = this._recipientFeatureFlags.featureFlags();
    const currentPath = this._location.path(false);
    const newHash = `#acc.${hashtag}`;
    const gsHash = accredishare_tracking ? this._getGSHashTag() : ''; // TODO(MartinN) remove after Accredishare is released

    if (!currentPath.endsWith(newHash)) {
      this._location.replaceState(`${currentPath}${gsHash}${newHash}`);
    }
  }

  private _hasReferralData(referralData: ReferralData): boolean {
    return !!referralData.hashtag || !!referralData.utm_medium || !!referralData.utm_source;
  }

  private _handleError<T>() {
    return (error: any): Observable<T | null> => {
      console.error('AccredishareService error:', error);
      return of(null);
    };
  }

  private _handleSessionId({ session_id }: AccredishareTrackResponse): void {
    const sessionId = this._getSessionId();

    if (!sessionId && session_id) {
      this._storageService.set(this.ACCREDISHARE_STORAGE, session_id);
    }
  }

  private _getSessionId(): string | null {
    return this._storageService.get(this.ACCREDISHARE_STORAGE);
  }

  private _getPlatform(): PlatformType {
    if (this._platform.ANDROID || this._platform.IOS) {
      return PlatformType.MOBILE;
    }
    return PlatformType.DESKTOP;
  }

  private _getHashTag(fragment: string, hashTagType: HashTagType = 'acc.'): string | null {
    return (
      fragment
        ?.split('#')
        .find((tag) => tag.startsWith(hashTagType))
        ?.replace(hashTagType, '') || null
    );
  }

  private _getGSHashTag(): string {
    const fragment = this._route.snapshot.fragment || this._getFragmentFromLocation();
    const gsHashTag = this._getHashTag(fragment, 'gs.');
    return gsHashTag ? `#gs.${gsHashTag}` : '';
  }

  private _getFragmentFromLocation(): string {
    const locationPathWithHash = this._location.path(true);
    const firstHashLocation = locationPathWithHash.search(/#/);
    if (firstHashLocation < 0) {
      return '';
    }

    return locationPathWithHash.substring(firstHashLocation);
  }

  private _getRouteInfo(): { fragment: string | null; queryParams: Params } {
    return {
      fragment: this._route.snapshot.fragment,
      queryParams: this._route.snapshot.queryParams,
    };
  }
}
